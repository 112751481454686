import React, {useState} from 'react'
import removal1 from '../assets/removal-1.png'
import avatar from '../assets/Mawingubluelogo.png'

import axios from 'axios';
import { API_URL, secret_key, currentYear } from '../General'
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';

// import Menubar from './MenuBar';

const WifiReset = () => {
const storedData = localStorage?.getItem('user');

if (storedData) {
  var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
  var logeduserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}
// initialize form values
const [data, setData] = useState({
    newpass: "",
    confirm_pass: ""
})
let navigate = useNavigate();

const handleGoBack = () => {
  navigate(-1); // Go back one step in the navigation history
};

// initialize error message
const [errorMessage, setErrorMessage] = useState('');
const [loading, setLoading] = useState(false);

// set change form values
const handleChange = (e) =>{
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
}
// login user
const changeWifiPass = (e) =>{
      e.preventDefault();
      setLoading(true);

      let formData = {
        "custno": logeduserData.Customer_no,
        "full_name": logeduserData.full_name,
        "new_pass": data.newpass
      };

      if(data.newpass !== data.confirm_pass){
        setErrorMessage("Passwords do not match");
        console.log(data)
        setLoading(false)
      }else{
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${API_URL}/change_WifiPassword`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : formData
        };
        
        axios.request(config)
        .then((response) => {
          setErrorMessage("")
          setLoading(false);       
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Wifi Password changed",
          })
          .then(() => {
            navigate(0); 
          });
          
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(error?.response?.data?.error)
        });
  
      }     
    }

    const removeError = () => {
      setErrorMessage("")
    }

    const logout = () => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate(0);
      };
    
    return (
        <div className="self-care">
            
            <div>
                <button onClick={logout} className="logout-button">
                    Logout
                </button>
                <button onClick={handleGoBack} className="back-button" >
                  &lt;&lt; Back
                </button>
            </div>
            <div className="row auth-container">
                <div className="col-6 login-content">
                  <div className='logForm rectangle'>
                    <form onSubmit={changeWifiPass}>
                        <img src={avatar} alt='' />
                        <div className="text-wrapper-2">Karibu To Self-Care Portal</div>                        
                        <div className="log-in">Change WIFI Password</div>
                        <input
                            type="text"
                            className="form-control formInfor"
                            placeholder="Enter New WIFI Password"
                            id="newpass"
                            onChange={handleChange}
                            onFocus={removeError}
                            required
                          />

                        <input
                            type="text"
                            className="form-control formInfor"
                            placeholder="Confirm WIFI Password"
                            id="confirm_pass"
                            onChange={handleChange}
                            onFocus={removeError}
                            required
                          />
                        
                        <input
                          type="submit"
                          className="Auth-btn"
                          value={loading ? 'Sending...' : 'Submit'}
                          disabled={loading}
                        />
                        {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}

                        {/* <button className='formInfor newuser' onClick={() => handleLoginUserClick()}>Log In</button> */}
                    </form>
                    <div className="support-call">
                      Support:
                      <br />
                      Call/Whatsapp: 011-1194-000
                      <br />
                      Email: info@mawingu.co
                    </div>
                    <div className="copyright">Copyright © {currentYear}-Mawingu</div>
                    </div>
                </div>
                <div className="col-6 Loginpageimg">
                    <img src={removal1} alt='' />
                </div>
            </div>
        </div>
    )
}

export default WifiReset