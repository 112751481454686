export const API_URL = 'https://selfcare-backend.mawingu.co';

export const secret_key = "mawingu@2023secret"
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
}
export const currentYear = new Date().getFullYear();

export const LANGUAGES = [
  { label: "English", code: "en" },
  { label: "Somali", code: "so" },
];
