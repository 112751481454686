import React, { useEffect, useState } from 'react'
import avatar from '../assets/Mawingubluelogo.png'
import { API_URL, secret_key, currentYear} from '../General';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Langpage from './Langpage';


function Receipts() {

  const storedData = localStorage?.getItem('user');
  const [payments, setPayments] =useState([])

  const [pdfData, setPdfData] =useState([])

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var logeduserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the navigation history
  };

  const custno = logeduserData.Customer_no

  const getCustomerReceipts =() =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/get_payment_receipts`,
      headers: { 
        'Content-Type': 'application/json'
      },
         data : {
          "custno": custno
         }
    };
    
    axios.request(config)
    .then((response) => {      
      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.rcp_date);
        const dateB = new Date(b.rcp_date);
      
        // Compare the dates to sort in descending order (newest first).
        return dateB - dateA;
      });
      
      setPayments(sortedData); 
      
      
    })
    .catch((error) => {
      console.log(error);
      // setErrorMessage(error?.response?.data?.error)
    });
  }

  useEffect(() => {
    getCustomerReceipts();
  }, [custno])

  const getReceiptPDF = (inv_no) =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${API_URL}/get_customer_invoices`,
      headers: { 
        'Content-Type': 'application/json'
      },
        data : {
          
            "DocumentNo":inv_no,
            "document_type":"RCP"
        
        }
    };

    console.log(inv_no)
    
    axios.request(config)
    .then((response) => {
      const byteCharacters = atob(response.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      setPdfData(blobUrl);
      
      // Preview the PDF
      window.open(blobUrl, '_blank');
    })
    .catch((error) => {
      console.log(error);
    });
}

  return (
    <div className="overlap-group1">
      <div className="logoutlang">
        <Langpage />
        <div>
          <button onClick={handleGoBack} className="back-button" >
            &lt;&lt; {t('back')}
          </button>
        </div>
      </div>
        
      <img src={avatar} alt='' />
      <div className="div23">
      <div className="text1-wrapper">{t('payment_receipts')}</div>
      
      <div className="wraprshead">
        <div className="text1-wrapper-3">{t('receipt_number')}</div>
        <div className="text1-wrapper-3">{t('date')}</div>
        <div className="text1-wrapper-3">{t('description')}</div>
        <div className="text1-wrapper-3">{t('amount')}</div>
        <div className="text1-wrapper-3">{t('action')}</div>
      </div>
      {/* <div className="wraprs">
        <div className="list-item">{payments.rcp_ref }</div>
        <div className="list-item">{payments.rcp_date}</div>
        <div className="list-item">Mpesa</div>
        <div className="list-item">{payments.rcp_description }</div>
        <div className="list-item">{payments.rcp_amount}</div>
      </div> */}

      {payments.map((payment)=>{
        return(
        <div className="wraprs">
        <div className="list-item">{payment.rcp_number}</div>
        <div className="list-item">{payment.rcp_date}</div>
        <div className="list-item">{payment.rcp_description}</div>
        <div className="list-item">{payment.rcp_amount}</div>
        <div className="list-item">{payment.length > 0}<a onClick={()=> getReceiptPDF(payment.rcp_number)} className="btn btn-sm btn-info">{t('view')}</a></div>
      </div>)
      })}
      
     
      </div>
      <div className="copyright">{t('copyright')} © {currentYear}-Mawingu</div>
    </div>
  )
}

export default Receipts