import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('selectedLanguage') || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
    resources: {
      en: {
        translation: {
          login: "Log in",
          welcome: "Welcome to the Mawingu Self-Care Portal",
          enter_cust_no: "Enter Account number",
          cust_no: "Account Number",
          enter_pin: "Enter PIN",
          pin: "PIN",
          remember_me: "Remember me",
          forgot_pass: "Forgot Password?",
          new_user: "New user",
          support: "Support",
          callwhatsapp: "Call/Whatsapp",
          email: "email",
          logging: "Logging in...",
          copyright: "Copyright",
          submit: "Submit",
          submitting: "Submitting...",
          enter_phone_number: "Enter Phone Number or Email",
          phone_number: "Phone Number or Email",
          back: "back",
          reset_password: "Reset Password",
          reset: "Reset",
          back_to_login: "Back to Log In",
          sending: "Sending...",
          welcomestaff: "Welcome",
          account_number: "Account number",
          phone: "Phone",
          current_packages: "Current packages",
          renewal_date: "Renewal date",
          change: "Change",
          wifi_password: "WiFi password",
          renew: "Renew",
          subscription: "subscription",
          download: "Download",
          invoice: "invoice",
          receipts: "receipts",
          logout: "Logout",
          change_wifi_password: "Change WIFI password",
          current_wifi_password: "Current WIFI password",
          enter_new_wifi_password: "Enter New WIFI password",
          confirm_wifi_password: "Confirm WIFI password",
          renew_subscription: "Renew subscription",
          phone_number_maessage: "Phone number(change if this is not paying number)",
          package_upgrade: "Package(upgrade or downgrade package)",
          subscription_amount: "Subscription amount",
          payment_receipts: "Payment Receipts",
          receipt_number: "Receipt number",
          date: "Date",
          description: "Description",
          amount: "Amount",
          action: "Action",
          view: "View",
          invoices: "Invoices",
          invoice_number: "Invoice number"
        },
      },
      so: {
        translation: {
          login: "Soo gal",
          welcome: "Ku soo dhawoow iskuxirka Is-daryeelka Mawingu",
          enter_cust_no: "Geli Lambarka ciwaankaaga",
          cust_no: "Lambarka ciwaankaaga",
          enter_pin: "Geli lambarka sirta ah",
          pin: "Erayga sirta ah",
          remember_me: "I xasuuso",
          forgot_pass: "Ilowday erayga sirta ah?",
          new_user: "Isticmaale cusub",
          support: "caawin",
          callwhatsapp: "Wac/Whatsapp",
          email: "iimaylka",
          logging: "Galida...",
          copyright: "xuquuqda daabacaada",
          submit: "Gudbi",
          submitting: "Gudbinta...",
          enter_phone_number: "Geli Lambarka Taleefanka ama iimaylka",
          phone_number: "Lambarka Taleefanka ama iimaylka",
          back: "dib",
          reset_password: "Dib u deji erayga sirta ah",
          reset: "Dib u dajin",
          back_to_login: "Ku laabo galitaanka",
          sending: "Diraya...",
          welcomestaff: "Soo dhawoow",
          account_number: "Lambarka ciwaankaaga",
          phone: "Taleefanka",
          current_packages: "xirmada hadda",
          renewal_date: "Taariikhda cusboonaysiinta",
          change: "Beddel furaha",
          wifi_password: "sirta ah ee WiFi",
          renew: "Cusbooneysii",
          subscription: "isdiiwaangelinta",
          download: "Soo deji",
          invoice: "qaansheegta",
          receipts: "rasiidhada",
          logout:"Ka bax",
          change_wifi_password: "Beddel furaha sirta ah ee WiFi ga",
          current_wifi_password: "Furaha sirta ah ee WiFi hadda",
          enter_new_wifi_password: "Geli furaha cusub ee WiFi ga",
          confirm_wifi_password: "Xaqiiji erayga sirta ah ee WiFi ga",
          renew_subscription: "Cusbooneysii isdiiwaangelinta",
          phone_number_maessage: "Lambarka taleefanka (beddel haddii kanu aanu ahayn lambarka lacag bixinta)",
          package_upgrade: "Xirmo (kor ama hoos u dhig xirmadha)",
          subscription_amount: "Qiimaha  isdiiwaangelinta",
          payment_receipts: "Rasiidhada lacag bixinta",
          receipt_number: "Lambarka rasiidka",
          date: "Taariikhda",
          description: "Sharaxaada",
          amount: "Qiimaha",
          action: "Ficil",
          view: "Fiiri/eeg",
          invoices: "Qaansheegyada",
          invoice_number: "Lambarka qaansheegta"

        }
      },
    },
  });

export default i18n;